import React from 'react';
import Helmet from 'react-helmet';
import { AnalyticsLocationProvider } from 'latitude-analytics';
import { graphql } from 'gatsby';
import { Link } from '@latitude/link';
import Layout from '../components/layout';
import Hero from '../components/Hero/Hero';
import Section from '../components/Section/Section';
import ArticleTile from '../templates/life-done-better/ArticleTile';
import PageData from '../data/pages/life-done-better.json';

export default class LifeDoneBetter extends React.Component {
  constructor(props) {
    super(props);
    this.itemsToShowStep = 12;
    this.articles = props.data.allMarkdownRemark.edges;
    this.categories = [
      {
        tags: 'all',
        title: 'Latest articles'
      },
      {
        tags: 'loans',
        title: 'Loans'
      },
      {
        tags: 'credit cards',
        title: 'Credit cards'
      },
      {
        tags: 'insurance',
        title: 'Insurance'
      }
    ];
    this.state = {
      tags: this.categories[0].tags,
      itemsToShow: this.itemsToShowStep
    };
  }

  getArticlesByTag(tag) {
    if (!this.articlesByTag) {
      this.articlesByTag = [];
      this.articlesByTag['all'] = [];
      this.articles.map(edge => {
        const item = edge.node.frontmatter;
        if (item.tags) {
          item.tags.map(itemTag => {
            if (!this.articlesByTag[itemTag]) {
              this.articlesByTag[itemTag] = [];
            }
            this.articlesByTag[itemTag].push(item);
          });
        }
        this.articlesByTag['all'].push(item);
      });
    }

    return this.articlesByTag[tag];
  }

  render() {
    const pageTitle = 'Life done better';

    return (
      <Layout location={this.props.location}>
        <main className="navigation-spacer">
          <Helmet>
            <link
              rel="canonical"
              href="https://www.latitudefinancial.com.au/life-done-better/"
            />
            <title>{PageData.title}</title>
            <meta name="description" content={PageData.meta.description} />
          </Helmet>
          <Hero
            h1={pageTitle}
            intro="That's what we stand for. Whether it's with one of our personal loans; a credit card; or a range of insurance policies, Latitude Financial Services is here to help you grab hold of your dreams and live the life you want."
            darkBg
            bg={
              require('../../static/life-done-better/images/banner-the-ins-and-outs-of-managing-your-household-budget.webp')
                .default
            }
          />
          <Section>
            {/* <div className="d-md-flex">
              {this.categories.map((category, i) => {
                let opts = {};
                if (this.state.tags.indexOf(category.tags) !== -1) {
                  opts['secondary'] = true;
                } else {
                  opts['tertiary'] = true;
                }
                return (
                  <Button
                    href="javascript:void"
                    {...opts}
                    key={i}
                    onClick={e => {
                      this.setState({
                        tags: category.tags,
                        itemsToShow: this.itemsToShowStep
                      });
                    }}
                  >
                    {category.title}
                  </Button>
                );
              })}
            </div> */}
            <div className="row">
              {this.getArticlesByTag(this.state.tags).map((article, i) => {
                if (i < this.state.itemsToShow) {
                  // const article = item.node.frontmatter;
                  return (
                    <div key={article.id} className="col-sm-6 col-lg-4">
                      <AnalyticsLocationProvider location={pageTitle}>
                        <ArticleTile {...article} />
                      </AnalyticsLocationProvider>
                    </div>
                  );
                }
              })}
            </div>

            {this.state.itemsToShow <
              this.getArticlesByTag(this.state.tags).length && (
              <div className="text-center">
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <Link
                  href="javascript:void(0);"
                  onClick={() => {
                    // eslint-disable-next-line react/no-access-state-in-setstate
                    const prevItemsToShow = this.state.itemsToShow;
                    this.setState({
                      itemsToShow: prevItemsToShow + this.itemsToShowStep
                    });
                  }}
                  style={{
                    borderBottom: 'none',
                    fontWeight: '700'
                  }}
                >
                  View more articles
                </Link>
              </div>
            )}
          </Section>
        </main>
      </Layout>
    );
  }
}

export const query = graphql`
  query {
    allMarkdownRemark(
      filter: {
        fileAbsolutePath: {
          glob: "**/src/templates/life-done-better/articles/*.md"
        }
      }
      sort: { order: DESC, fields: [frontmatter___date] }
      limit: 1000
    ) {
      edges {
        node {
          html
          frontmatter {
            id
            title
            description
            author
            date
            path
            thumbnail
            tags
          }
        }
      }
    }
  }
`;
